import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { Statistic, Divider, Label } from 'semantic-ui-react';
import Layout from '../components/layout';
import Page from '../components/Page';
import Country from '../components/country';
import SEO from '../components/seo';

const CategoryLink = styled(Link)`
  margin-right: 10px;
`;

const CountryTemplate = ({ pageContext: context, uri }) => {
  const {
    featuredImage, title, countryData, categories, content,
  } = context;
  // Set to Default Background Image
  const backgroundImage = featuredImage
    ? featuredImage.sourceUrl
    : '';

  return (
    <Layout>
      <SEO
        title={`${title} | Merchants of Pearls`}
        description={`Pray for the country of ${title} and its people. …hope and peace that comes only from Jesus Christ.`}
        type="article"
        image={backgroundImage}
        url={uri}
      />
      <Page.Wrapper>
        <Page.Image img={backgroundImage} />
        <Page.Content top>
          <Page.Title>{title}</Page.Title>

          {/* eslint-disable-next-line react/no-danger */}
          <div dangerouslySetInnerHTML={{ __html: content }} />
          <Country.SectionHeader>Summary</Country.SectionHeader>
          <Country.Summary dangerouslySetInnerHTML={{ __html: countryData.summary }} />
          <Country.FigureImage>
            <img alt={countryData.mapImage.alt_text} src={countryData.mapImage.sourceUrl} />
            {/* eslint-disable-next-line react/no-danger */}
            <figcaption dangerouslySetInnerHTML={{ __html: countryData.mapImage.caption }} />
          </Country.FigureImage>

        </Page.Content>
        <Page.Content full background="#f9fafb">

          <Country.SectionHeader>Statistics</Country.SectionHeader>
          <Country.Statistics>
            <Country.Statistic>
              <Statistic.Label>People Groups</Statistic.Label>
              <Statistic.Value>{countryData.statistics.peopleGroups}</Statistic.Value>
            </Country.Statistic>
            <Country.Statistic>
              <Statistic.Label>Population</Statistic.Label>
              <Statistic.Value>{countryData.statistics.population.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Statistic.Value>
            </Country.Statistic>
            <Country.Statistic>
              <Statistic.Label> Unreached</Statistic.Label>
              <Statistic.Value>
                {countryData.statistics.percentUnreached}
                %
              </Statistic.Value>
            </Country.Statistic>
          </Country.Statistics>
          <Country.Subhead>From the The Joshua Project [2]</Country.Subhead>
        </Page.Content>
        <Page.Content>
          <Country.SectionHeader>In the News</Country.SectionHeader>
          <Country.News dangerouslySetInnerHTML={{ __html: countryData.currentNews }} />
        </Page.Content>
        <Country.FigureImage>
          <img
            alt={countryData.landscapeImage.alt_text}
            src={countryData.landscapeImage.sourceUrl}
          />
          {/* eslint-disable-next-line react/no-danger */}
          <figcaption dangerouslySetInnerHTML={{ __html: countryData.landscapeImage.caption }} />
        </Country.FigureImage>
        <Page.Content>
          <Country.SectionHeader>Prayer Guide</Country.SectionHeader>
          {/* eslint-disable-next-line react/no-danger */}
          <div dangerouslySetInnerHTML={{ __html: countryData.prayerGuide }} />
        </Page.Content>
        <Page.Content>
          {categories.map((cat) => (
            <CategoryLink key={JSON.stringify(cat)} to={`/categories/${cat.slug}`}>
              <Label as="span">{cat.name}</Label>
            </CategoryLink>
          ))}
          <Divider />
          <h3>Footnotes</h3>
          <Country.Footnotes>
            <Country.Footnote>
              [1]
              <a href={countryData.sources.worldFactbaook}> World Factcbook</a>
            </Country.Footnote>
            <Country.Footnote>
              [2]
              <a href={countryData.sources.joshuaProject}> Joshua Project</a>
            </Country.Footnote>
            <Country.Footnote>
              [3]
              <a href={countryData.sources.operationWorld}> Operation World</a>
            </Country.Footnote>
            { countryData.sources.other && (
            <Country.Footnote>
              [4]
              {countryData.sources.ohter}
            </Country.Footnote>
            )}
          </Country.Footnotes>
        </Page.Content>
      </Page.Wrapper>
    </Layout>
  );
};

CountryTemplate.defaultProps = {};
CountryTemplate.propTypes = {
  uri: PropTypes.string.isRequired,
  pageContext: PropTypes.shape({
    featuredImage: PropTypes.shape({
      sourceUrl: PropTypes.string.isRequired,
    }),
    title: PropTypes.string.isRequired,
    categories: PropTypes.any,
    countryData: PropTypes.shape({
      statistics: PropTypes.shape({
        population: PropTypes.number,
        peopleGroups: PropTypes.number,
        percentUnreached: PropTypes.number,
      }),
      sources: PropTypes.shape({
        population: PropTypes.string,
        peopleGroups: PropTypes.string,
        percentUnreached: PropTypes.string,
      }),
      currentNews: PropTypes.string,
    }),
    content: PropTypes.string,
  }).isRequired,
};

export default CountryTemplate;
