import styled from 'styled-components';
import { Statistic } from 'semantic-ui-react';
import theme from '../styles/theme';

const CountrySummary = styled.div`

`;
const CountryFigureImage = styled.figure`
  margin: 0;

  img {
    width: 100%;
  }

  figcaption {
    font-size: 10px;
    text-align: center;
  }
`;

const CountryNews = styled.div`
  text-align: center;
  font-size: 28px;
  li {
    margin-bottom: 40px;
  }
  ul {
    list-style: none;
    padding: 0;
  }

`;

const CountryFootnote = styled.li``;
const CountryFootNotes = styled.ul`
  list-style: none;
  font-size: 12px;
  padding: 0;
`;

const CountryStatistics = styled.div`
  justify-content: space-around;
  display: flex;
  padding: 100px 20px;

  ${theme.breakpoints.mobile} {
    flex-direction: column;
    padding: 10px;
    min-height: 400px;
  }
`;

const CountryStatistic = styled(Statistic)`
    margin: 0 !important;
`;

const CountrySectionHeader = styled.h2`
  text-align: center;
  font-size: 28px;
`;

const CountrySubhead = styled.p`
  text-align: center;
  font-size: 12px;
  font-weight: 100;
`;

const Country = {
  Summary: CountrySummary,
  FigureImage: CountryFigureImage,
  Statistics: CountryStatistics,
  Statistic: CountryStatistic,
  SectionHeader: CountrySectionHeader,
  News: CountryNews,
  Footnotes: CountryFootNotes,
  Footnote: CountryFootnote,
  Subhead: CountrySubhead,
};

export default Country;
